import React from 'react';
import './App.css';
import Header from "./components/Header"
import DataBlocks from "./components/DataBlocks"
import Footer from "./components/Footer"

function App() {
  let links: object[] = [
    {
      text: "PROFILE",
      href: "#dataBlock",
      px: 150,
      id: "1"
    },
    {
      text: "WORK EXPERIENCE",
      href: "https://www.google.com/",
      px: 625,
      id: "2"
    },
    {
      text: "PROJECTS",
      href: "https://www.google.com/",
      px: 1125,
      id: "3"
    },
    {
      text: "SKILLS",
      href: "https://www.google.com/",
      px: 2200,
      id: "4"
    },
    {
      text: "CONTACT",
      href: "https://www.google.com/",
      px: 2200,
      id: "5"
    }];
  
  return (
    <div className="App">
      <main>
        <React.Fragment>
          <Header links = {links}/>
          <DataBlocks />
          <Footer links= {links}/>
          <div className="sideLinks">
            <a href="https://github.com/KenanRustamov"><svg height="25" className="dynamicLogo" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"  /></svg></a>
            <a href="https://www.linkedin.com/in/kenanrustamov/"><svg className= "dynamicLogo" xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg></a>
            <a href="https://www.instagram.com/kenanrustamov1/">
              <svg className= "dynamicLogo" role="img" height="25" viewBox="0 0 24 24">
                <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z"/></svg>
            </a>  
            <a href="https://drive.google.com/file/d/15BdRuMDiaGqg2yrfeMohbhQGvszbVZWc/view?usp=sharing" target="_blank" rel="noopener noreferrer"><svg className="dynamicLogo" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.55 122.88"><path d="M102.55 122.88H0V0h77.66l24.89 26.44v96.44zm-6.42-6.9V30.44H73.44V5.91H6.51v110.07h89.62z" fill-rule="evenodd" clip-rule="evenodd"/></svg></a>
            <div>
              <svg width="7px" height="100%">
                <line x1="0" y1="0" x2="0" y2="200"/>
             </svg>
            </div>
          </div>
        </React.Fragment>
      </main>
    </div>
  );
}

export default App;
